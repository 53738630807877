import styled from "styled-components";
import arrowDownPng from "../../../images/arrow-down.png";

export const Root = styled.div`
  min-width: 1440px;
  margin: 27px auto;

  @media (max-width: 800px) {
    min-width: auto;
    margin-top: 24px;
    margin-bottom: 18px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 18px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;

  @media (max-width: 800px) {
    align-items: stretch;
    flex-direction: column;
    gap: 18px;
  }
`;

export const RightSide = styled.div`
  display: flex;
  gap: 23px;
  align-items: center;

  @media (max-width: 800px) {
    gap: 6px;
    justify-content: space-between;
  }
`;

export const NavItem = styled.span`
  color: black;
  text-decoration: none;
  font-size: 11px;
  font-weight: 600;
  line-height: 11px;
  cursor: pointer;
`;

export const NavItemWrapper = styled.span`
  border-bottom: 2px solid black;

  & > ${NavItem} {
    font-size: 11px;
    font-weight: 600;
  }
`;

export const Title = styled.span`
  font-size: 25px;
  margin-right: 9px;

  @media (max-width: 800px) {
    text-align: left;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  gap: 9px;

  @media (max-width: 800px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const Select = styled.span`
  background-image: url(${arrowDownPng});
  background-position: center right 12px;
  background-repeat: no-repeat;
  background-size: 10px auto;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 5px 30px 5px 12px;
  font-size: 11px;
  font-weight: 600;
`;
