import { HeaderWithSliderBlock } from "../blocks/_home-page/header-with-slider-block/header-with-slider-block";
import { Footer } from "../blocks/footer/footer";

export const ComingSoon = () => {
  return (
    <>
      <HeaderWithSliderBlock />
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '2rem', height: '500px'}}>Coming Soon</div>
      <Footer />
    </>
  );
};
