import styled from "styled-components";

export const Root = styled.div`
  margin-top: 20px;

  & img {
    border-radius: 4px;
    width: 100%;

    @media (max-width: 800px) {
      border-radius: 0;
    }
  }
`;
