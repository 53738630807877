import styled from "styled-components";
import { Button } from "./lot-header-block.styled";

export const Root = styled.div`
  margin-top: 12px;
  padding: 0 15px;

  @media (min-width: 1439px) {
    display: none;
  }
`;

export const Rows = styled.div`
  display: block;
`;

export const LeftRow = styled.div`
  display: block;
`;

export const RightRow = styled.div`
  flex-grow: 1;
  width: 252px;
`;

export const Labels = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const Label = styled.div`
  padding: 9px 16px;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-size: 11px;
  line-height: 16px;

  > b {
    font-weight: 600;
  }
`;

export const Text = styled.div`
  font-size: 15px;
  text-align: justify;
  line-height: 23px;
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 12px;
`;

export const GalleryWrapper = styled.div`
  margin-top: 40px;
`;

export const Essentials = styled.div`
  padding: 25px;
  background-color: #f7f7f7;
  border-radius: 4px;
  gap: 14px;
  display: flex;
  flex-direction: column;
`;

export const EssentialsTitle = styled.div`
  font-size: 22px;
  margin-bottom: 9px;
`;

export const EssentialsText = styled.div`
  font-size: 15px;

  & > b {
    font-size: 14px;
  }
`;

export const Ul = styled.ul`
  padding: 0 0 0 10px;
  margin: 2px 0;

  > li {
    font-size: 12px;

    &::marker {
      font-size: 7px;
    }
  }
`;

export const ListText = styled.div`
  font-size: 13px;
  line-height: 24px;
`;

export const ContactButton = styled(Button)`
  text-align: center;
  justify-content: center;
  margin-top: 15px;
  padding: 10px 12px;
  font-size: 15px;
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  padding-right: 14px;
  margin-bottom: 18px;

  > img {
    cursor: pointer;
  }
`;

export const SideGalleries = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 66px;
`;

export const SideCard = styled.div`
  position: relative;
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

export const SideTitle = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
`;

export const SideImageWrapper = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  > img {
    display: flex;
    width: 100%;
    border-radius: 4px;
  }
`;

export const SideImageWrapperLast = styled(SideImageWrapper)`
  height: 55px;
`;

export const GradientLight = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 65px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;

  & > span {
    position: absolute;
    display: block;
    width: 100%;
    text-align: center;
    bottom: 0;
    font-size: 10px;
    font-weight: bold;
  }
`;

export const SideButton = styled(Button)`
  text-align: center;
  justify-content: center;
`;

export const SideLabels = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

export const SideLabel = styled.div`
  width: calc(100% / 2 - 5px / 2);
  border-radius: 4px;
  border: 1px solid lightgray;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  padding: 4px 0;
  cursor: pointer;
`;

export const CardDescription = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 70%;
  height: 100%;
  background-color: rgba(247, 247, 247, 0.95);
  padding-left: 12px;
`;

export const CardDescriptionTop = styled.div`
  padding-top: 20px;
  padding-bottom: 9px;
  border-bottom: 1px solid black;
  position: relative;

  > img {
    position: absolute;
    top: 12px;
    right: 17px;
    width: 14px;
  }

  > div:first-child {
    font-size: 10px;
    line-height: 10px;
  }

  > div:nth-child(2) {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
  }

  > div:nth-child(3) {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
  }
`;

export const CardDescriptionBottom = styled.div`
  padding-top: 5px;
  padding-right: 15px;

  > div:first-child {
    font-size: 11px;
    line-height: 10px;
  }

  > div:nth-child(2) {
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }

  > div:nth-child(3) {
    font-size: 12px;
    line-height: 14px;
  }

  > div:nth-child(4) {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  }

  > div:nth-child(5) {
    font-size: 8px;
    font-weight: 700;
    border: 1px solid black;
    border-radius: 4px;
    text-align: center;
    margin-top: 5px;
  }
`;

export const CommentsWrapper = styled.div`
  margin-top: 43px;
`;
