import { useEffect, useState } from "react";
import { Container } from "../../../app.styled";
import {
  LeftSide,
  NavItem,
  NavItemWrapper,
  RightSide,
  Root,
  SelectWrapper,
  Title,
  Wrapper,
} from "./filters-block.styled";
import { carouselsData } from "../../../data/carousels-data";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const FiltersBlock = ({applyYearFilter, applyTransmissionFilter, applyBodyStyleFilter, applySort}) => {
  const [minYear, setMinYear] = useState(0);
  const [maxYear, setMaxYear] = useState(0);
  const [year, setYear] = useState(0);
  const [sort, setSort] = useState('ending-soon');
  const [transmission, setTransmission] = useState(0);
  const [bodyStyle, setBodyStyle] = useState(0);
  useEffect(() => {
    let min = 2000;
    let max = 2000;
    carouselsData.map(item => {
      if (item.year < min) min = item.year;
      if (item.year > max) max = item.year;
    })
    setMinYear(min)
    setMaxYear(max)
  }, []);
  const getYearArray = () => {
    const arr = [];
    for(let i = minYear; i <= maxYear; i++) {
      arr.push(i)
    }
    return arr;
  }
  const transmissionData = [
    'manual',
    'automatic',
    'robotic',
    'CVT'
  ];
  const bodyStyleData = [
    'coupe',
    'suv',
    'hatchback',
    'sedan'
  ];

  return (
    <Root data-qa="FiltersBlock">
      <Container>
        <Wrapper>
          <LeftSide>
            <Title>AUCTIONS</Title>
            <SelectWrapper>
            <FormControl sx={{ m: 1, minWidth: 100, fontSize: '14px' }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={year}
                onChange={e => {
                  setYear(e.target.value);
                  e.target.value === 0 ? applyYearFilter(null) : applyYearFilter(e.target.value)
                }}
                sx={{fontSize: '12px'}}
              >
                <MenuItem value={0} sx={{fontSize: '12px'}}>YEAR</MenuItem>
                {getYearArray().map(item => <MenuItem sx={{fontSize: '12px'}} value={item}>{item}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 100, fontSize: '14px' }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={transmission}
                onChange={e => {
                  setTransmission(e.target.value);
                  e.target.value === 0 ? applyTransmissionFilter(null) : applyTransmissionFilter(e.target.value);
                }}
                sx={{fontSize: '12px'}}
              >
                <MenuItem value={0} sx={{fontSize: '12px'}}>TRANSMISSION</MenuItem>
                {transmissionData.map(item => <MenuItem sx={{fontSize: '12px'}} value={item}>{item}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 100, fontSize: '14px' }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={bodyStyle}
                onChange={e => {
                  setBodyStyle(e.target.value);
                  e.target.value === 0 ? applyBodyStyleFilter(null) : applyBodyStyleFilter(e.target.value);
                }}
                sx={{fontSize: '12px'}}
              >
                <MenuItem value={0} sx={{fontSize: '12px'}}>BODY STYLE</MenuItem>
                {bodyStyleData.map(item => <MenuItem sx={{fontSize: '12px'}} value={item}>{item}</MenuItem>)}
              </Select>
            </FormControl>
              {/* <Select>YEARS</Select>
              <Select>TRANSMISSION</Select>
              <Select>BODY STYLE</Select> */}
            </SelectWrapper>
          </LeftSide>
          <RightSide>
            <NavItem style={{borderBottom: `${sort === "ending-soon" ? '2px solid black' : 'none'}`}} onClick={e => {
              e.preventDefault();
              setSort('ending-soon')
              applySort('ending-soon');
            }}>ENDING SOON</NavItem>
            <NavItem style={{borderBottom: `${sort === "new-listed" ? '2px solid black' : 'none'}`}} onClick={e => {
              e.preventDefault();
              setSort('new-listed');
              applySort('new-listed');
            }}>NEW LISTED</NavItem>
            <NavItem style={{borderBottom: `${sort === "no-reserve" ? '2px solid black' : 'none'}`, cursor: 'not-allowed'}} href="?sort=no-reserve">NO RESERVE</NavItem>
            <NavItem style={{borderBottom: `${sort === "lowest-mileage" ? '2px solid black' : 'none'}`}} onClick={e => {
              e.preventDefault();
              setSort('lowest-mileage');
              applySort('lowest-mileage');
            }
          }>LOWEST MILEAGE</NavItem>
          </RightSide>
        </Wrapper>
      </Container>
    </Root>
  );
};
