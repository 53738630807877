import React from "react";
import {
  Root,
  SearchInput,
  SearchWrapper,
  SearchText,
} from "./search-block.styled";
import searchBlockBg from "../../images/search-block-bg.png";

export const SearchBlock = () => {
  return (
    <Root img={searchBlockBg}>
      <SearchText>
        <div>Your daily digest of everything</div>
        <div>happening on the site.</div>
      </SearchText>
      <SearchWrapper>
        <SearchInput placeholder="Enter your email" />
        <button>SIGN UP</button>
      </SearchWrapper>
    </Root>
  );
};
