import { NarrowContainer } from "../../app.styled";
import posterImage from "../../images/_lot-page/main1.jpg";
import { Root } from "./lot-poster-block.styled";

export const LotPosterBlock = () => {
  return (
    <Root>
      <NarrowContainer>
        <img src={posterImage} alt="poster" />
      </NarrowContainer>
    </Root>
  );
};
