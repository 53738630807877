import styled from "styled-components";
import arrowRight from "../../images/arrow-right.svg";

export const Root = styled.div`
  margin-top: 18px;
`;

export const Title = styled.div`
  font-size: 21px;

  & > span {
    font-size: 15px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 12px;
  white-space: nowrap;

  @media (max-width: 800px) {
    margin-bottom: 16px;
  }
`;

export const Link = styled.a`
  color: black;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  padding-right: 12px;
  line-height: 16px;
  background-image: url(${arrowRight});
  background-position-x: 68px;
  background-position-y: center;
  background-size: 14px 13px;
  background-repeat: no-repeat;

  @media (max-width: 800px) {
    background-position-x: 80px;
    font-size: 17px;
    background-size: 16px 23px;
  }
`;

export const GalleryImages = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;

  & > img {
    border-radius: 4px;
    width: calc(100% / 4 - 4px);
  }

  @media (max-width: 800px) {
    gap: 7px;

    & > img {
      width: calc(100% / 2 - 4px);
    }
  }
`;
