import { HeaderBlock } from "../blocks/header-block/header-block";
import { LotHeaderBlock } from "../blocks/_lot-page/lot-header-block";
import { LotPosterBlock } from "../blocks/_lot-page/lot-poster-block";
import { LotDescriptionBlock } from "../blocks/_lot-page/lot-description-block";
import { Footer } from "../blocks/footer/footer";
import { SearchBlock } from "../blocks/serach-block/search-block";
import { ControlPanel } from "../blocks/_lot-page/control-panel/control-panel";
import { LotDescriptionBlockMobile } from "../blocks/_lot-page/lot-description-block-mobile";
import { ScrollRestoration } from "react-router-dom";
import React from "react";

export const LotPage = () => {
  return (
    <>
      <ScrollRestoration />
      <HeaderBlock />
      <LotHeaderBlock />
      <LotPosterBlock />
      <LotDescriptionBlock />
      <LotDescriptionBlockMobile />
      <SearchBlock />
      <Footer />
      <ControlPanel />
    </>
  );
};
