import { Button } from "../../blocks/_lot-page/lot-header-block.styled";
import { Footer, Root } from "./comments-form.styled";

export const CommentsForm = () => {
  return (
    <Root>
      <textarea
        name="comment"
        id="comment"
        placeholder="Add a comment here"
        cols="30"
        rows="4"
      ></textarea>
      <Footer>
        <div>
          <u>Keep me in this conversation via email</u>
        </div>
        <Button>SUBMIT</Button>
      </Footer>
    </Root>
  );
};
