import { MainHeaderBlock } from "../main-header-block/main-header-block";
import { NavigationBlock } from "../navigation-block/navigation-block";

export const HeaderBlock = (props) => {
  return (
    <div>
      <MainHeaderBlock dark />
      <NavigationBlock dark />
    </div>
  );
};
