import exterior1 from "../images/_lot-page/exterior1.jpg";
import exterior2 from "../images/_lot-page/exterior2.jpg";
import exterior3 from "../images/_lot-page/exterior3.jpg";
import exterior4 from "../images/_lot-page/exterior4.jpg";
import exterior5 from "../images/_lot-page/exterior5.jpg";
import exterior6 from "../images/_lot-page/exterior6.jpg";
import exterior7 from "../images/_lot-page/exterior7.jpg";
import exterior8 from "../images/_lot-page/exterior8.jpg";
import interior1 from "../images/_lot-page/interior1.jpg";
import interior2 from "../images/_lot-page/interior2.jpg";
import interior3 from "../images/_lot-page/interior3.jpg";
import interior4 from "../images/_lot-page/interior4.jpg";
import interior5 from "../images/_lot-page/interior5.jpg";
import interior6 from "../images/_lot-page/interior6.jpg";
import interior7 from "../images/_lot-page/interior7.jpg";
import interior8 from "../images/_lot-page/interior8.jpg";
import mechanical1 from "../images/_lot-page/mechanical1.jpg";
import mechanical2 from "../images/_lot-page/mechanical2.jpg";
import mechanical3 from "../images/_lot-page/mechanical3.jpg";
import mechanical4 from "../images/_lot-page/mechanical4.jpg";
import mechanical5 from "../images/_lot-page/mechanical5.jpg";
import mechanical6 from "../images/_lot-page/mechanical6.jpg";
import mechanical7 from "../images/_lot-page/mechanical7.jpg";
import mechanical8 from "../images/_lot-page/mechanical8.jpg";
import documents1 from "../images/_lot-page/documents1.jpg";
import documents2 from "../images/_lot-page/documents2.jpg";
import documents3 from "../images/_lot-page/documents3.jpg";
import documents4 from "../images/_lot-page/documents4.jpg";
import documents5 from "../images/_lot-page/documents5.jpg";
import documents6 from "../images/_lot-page/documents6.jpg";
import documents7 from "../images/_lot-page/documents7.jpg";
import documents8 from "../images/_lot-page/documents8.jpg";

export const galleriesData = [
  {
    title: "EXTERIOR",
    count: 71,
    images: [
      exterior1,
      exterior2,
      exterior3,
      exterior4,
      exterior5,
      exterior6,
      exterior7,
      exterior8,
    ],
  },
  {
    title: "INTERIOR",
    count: 83,
    images: [
      interior1,
      interior2,
      interior3,
      interior4,
      interior5,
      interior6,
      interior7,
      interior8,
    ],
  },
  {
    title: "MECHANICAL",
    count: 83,
    images: [
      mechanical1,
      mechanical2,
      mechanical3,
      mechanical4,
      mechanical5,
      mechanical6,
      mechanical7,
      mechanical8,
    ],
  },
  {
    title: "DOCUMENTS",
    count: 83,
    images: [
      documents1,
      documents2,
      documents3,
      documents4,
      documents5,
      documents6,
      documents7,
      documents8,
    ],
  },
];
