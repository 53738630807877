import { Footer, FooterSection, Root, Table, Title } from "./bid-card.styled";
import { Button } from "../../blocks/_lot-page/lot-header-block.styled";

export const BidCard = () => {
  return (
    <Root>
      <Title>BID ON THIS LISTING</Title>
      <Table>
        <tr>
          <td>Current Bid</td>
          <td>
            <b>USD $550,000</b> by <u>lbuyfordgt</u>
          </td>
        </tr>
        <tr>
          <td>Time Left</td>
          <td>
            <b>5 days, 19 hours, 57 minutes, 22 seconds</b>
          </td>
        </tr>
        <tr>
          <td>Ends On</td>
          <td>
            <b>Saturday, November 19 at 5:09pm</b> <u>remind me</u>
          </td>
        </tr>
        <tr>
          <td>Bids</td>
          <td>
            <b>3</b>
          </td>
        </tr>
        <tr>
          <td>Place Bid</td>
          <td>
            <Button blue>REGISTER TO BID</Button>
          </td>
        </tr>
      </Table>
      <Footer>
        <FooterSection>
          <u>How bidding works</u>
          <span>|</span>
          <u>Watch auction</u>
        </FooterSection>
        <FooterSection>
          <div>5,675 views</div>
          <span>|</span>
          <div>714 watches</div>
        </FooterSection>
      </Footer>
    </Root>
  );
};
