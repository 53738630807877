import { Container } from "../../app.styled";
import {
  HeaderBlock,
  HeaderBlockMenu,
  InputWrapper,
  Logo,
  BurgerWrapper,
} from "../_home-page/header-with-slider-block/header-with-slider-block.styled";
import logo from "../../images/logo.svg";
import logoDark from "../../images/logo-dark.svg";
import likeIcon from "../../images/icons/like-active.png";
import likeIconDark from "../../images/icons/like-icon-dark-active.png";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import burgerIconDark from "../../images/icons/burger-icon-dark.svg";
import burgerIcon from "../../images/icons/burger-icon.svg";
import { MainHeaderMobileMenu } from "./main-header-mobile-menu";

export const MainHeaderBlock = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  function handleBurgerClick() {
    return () => {
      setIsMobileMenuOpen(true);
    };
  }

  function handleCloseMobileMenu() {
    return () => {
      setIsMobileMenuOpen(false);
    };
  }

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "visible";
    }
  }, [isMobileMenuOpen]);

  return (
    <div data-qa="MainHeaderBlock" style={{ position: "relative", zIndex: 3 }}>
      <Container>
        {isMobileMenuOpen && (
          <MainHeaderMobileMenu dark close={handleCloseMobileMenu} />
        )}
        <HeaderBlock>
          <Logo>
            <Link to="/">
              <img src={props.dark ? logoDark : logo} alt="logo" />
            </Link>
          </Logo>
          <BurgerWrapper onClick={handleBurgerClick()}>
            <img
              src={props.dark ? burgerIconDark : burgerIcon}
              alt="burgerIcon"
            />
          </BurgerWrapper>
          <InputWrapper dark={props.dark}>
            <input placeholder="Search for make, model, category" />
            <div />
          </InputWrapper>
          <HeaderBlockMenu dark={props.dark}>
            <a href="/">How It Works</a>
            <a href="/">Get Alerts</a>
            <img src={props.dark ? likeIconDark : likeIcon} alt="like" />
            <a href="/">Log In</a>
          </HeaderBlockMenu>
        </HeaderBlock>
      </Container>
    </div>
  );
};

MainHeaderBlock.defaultProps = {
  dark: false,
};
