const Progress_bar = ({ progress }) => {
  const Parentdiv = {
    height: "30px",
    width: "100%",
    backgroundColor: "#a0a0a0",
    borderRadius: "5px",
    position: "relative",
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: "#3a3a3a",
    textAlign: "right",
    borderRadius: "5px",
  };

  const textPrent = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    position: "absolute",
    top: "5px",
    padding: "0px 6px",
    color: "#FFF",
    fontWeight: "500",
  };
  const text = {
    cursor: "default",
  };
  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <div style={textPrent}>
          <div style={text}>BID: $1,507,000</div>
          <div style={text}>10:22</div>
        </div>
      </div>
    </div>
  );
};

export default Progress_bar;
