import styled from "styled-components";

export const Root = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;

  @media (max-width: 800px) {
    top: auto;
    bottom: 30px;
    left: 0;
  }
`;

export const DvorSobak = styled.div`
  @media (max-width: 800px) {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 10px;

    > div {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      gap: 5px;
    }
  }
`;
