import { PlayButton, Root } from "./player.styled";

export const Player = (props) => {
  return (
    <Root>
      <img src={props.poster} alt="player poste" />
      <PlayButton />
    </Root>
  );
};
