import { Footer, FooterSection, Root, Title, Text, TextWrapper } from "./bid-card-mobile.styled";
import { Button } from "../../blocks/_lot-page/lot-header-block.styled";

export const BidCardMobile = () => {
  return (
    <Root>
      <Title>BID ON THIS LISTING</Title>
      <TextWrapper>
          <Text>Current Bid</Text>
          <Text>
            <b>USD $550,000</b> by <u>lbuyfordgt</u>
          </Text>
          <Text>Time Left</Text>
          <Text>
            <b>5 days, 19 hours, 57 minutes, 22 seconds</b>
          </Text>
          <Text>Ends On</Text>
          <Text>
            <b>Saturday, November 19 at 5:09pm</b> <u>remind me</u>
          </Text>
          <Text>Bids</Text>
          <Text>
            <b>3</b>
          </Text>
          <Text>Place Bid</Text>
          <Text>
            <Button blue>REGISTER TO BID</Button>
          </Text>
      </TextWrapper>
      <Footer>
        <FooterSection>
          <u>How bidding works</u>
          <span>|</span>
          <u>Watch auction</u>
        </FooterSection>
        <FooterSection>
          <div>5,675 views</div>
          <span>|</span>
          <div>714 watches</div>
        </FooterSection>
      </Footer>
    </Root>
  );
};
