import {
  SliderBlock,
  SliderItem,
  DotsItem,
  DotsContainer,
  DotsItemWrapper,
  SliderItemMobile,
} from "../header-with-slider-block/header-with-slider-block.styled";
import React, { useEffect, useState } from "react";
import { sliderData } from "../../../data/slider-data";
import { ReviewBlock } from "../review-block/review-block";

export const HeaderSliderBlock = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  function switchSlide(index) {
    return () => {
      setCurrentIndex(index);
    };
  }

  function switchToNextSlide() {
    const isLastSlide = currentIndex === sliderData.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      switchToNextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  return (
    <SliderBlock data-qa="HeaderSliderBlock">
      <ReviewBlock data={sliderData[currentIndex].description} />
      <SliderItem img={sliderData[currentIndex].image} />
      <SliderItemMobile img={sliderData[currentIndex].imageMobile} />
      <DotsContainer>
        {sliderData.map((item, index) => {
          return (
            <DotsItemWrapper key={index} onClick={switchSlide(index)}>
              <DotsItem active={index === currentIndex} />
            </DotsItemWrapper>
          );
        })}
      </DotsContainer>
    </SliderBlock>
  );
};
