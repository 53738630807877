import { HeaderWithSliderBlock } from "../blocks/_home-page/header-with-slider-block/header-with-slider-block";
import { FiltersBlock } from "../blocks/_home-page/filters-block/filters-block";
import { Carousels } from "../blocks/_home-page/carousel-block/carousels";
import { RecentFeatures } from "../blocks/_home-page/recent-features-block/recent-features";
import { SearchBlock } from "../blocks/serach-block/search-block";
import { Footer } from "../blocks/footer/footer";
import { carouselsData } from "../data/carousels-data";
import { ScrollRestoration } from "react-router-dom";
import React, { useEffect, useState } from "react";

export const HomePage = () => {
  const [year, setYear] = useState(null);
  const [transmission, setTransmission] = useState(null);
  const [bodyStyle, setBodyStyle] = useState(null)
  const [data, setData] = useState([]);
  const [sort, setSort] = useState("ending-soon");

  useEffect(() => {
    let newArr = carouselsData;
    if (sort === "ending-soon") newArr = (carouselsData.slice(0).sort((a,b) => {
      if (Number(a.endsIn.replace(/:/g, '')) === Number(b.endsIn.replace(/:/g, ''))) return 0;
      return Number(a.endsIn.replace(/:/g, '')) < Number(b.endsIn.replace(/:/g, '')) ? -1 : 1
    }))
    if (sort === "new-listed") newArr = (carouselsData.slice(0).sort((a,b) => {
      if (Number(b.endsIn.replace(/:/g, '')) === Number(a.endsIn.replace(/:/g, ''))) return 0;
      return Number(b.endsIn.replace(/:/g, '')) < Number(a.endsIn.replace(/:/g, '')) ? -1 : 1
    }))
    if (sort === "lowest-mileage") newArr = (carouselsData.slice(0).sort((a,b) => {
      if (Number(a.mileage) === Number(b.mileage)) return 0;
      return Number(a.mileage) < Number(b.mileage) ? -1 : 1
    }))
    if(year) newArr = (newArr.filter(item => item.year === year.toString()))
    if(transmission) newArr = (newArr.filter(item => item.transmission === transmission))
    if(bodyStyle) newArr = (newArr.filter(item => item.bodyStyle === bodyStyle))
    setData(newArr)
  }, [year, transmission, bodyStyle, sort]);

  return (
    <>
      <ScrollRestoration />
      <HeaderWithSliderBlock />
      <FiltersBlock applyYearFilter={setYear} applyTransmissionFilter={setTransmission} applyBodyStyleFilter={setBodyStyle} applySort={setSort} />
      <Carousels items={data} />
      <RecentFeatures />
      <SearchBlock />
      <Footer />
    </>
  );
};
