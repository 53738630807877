import { NarrowContainer } from "../../app.styled";
import {
  Button,
  Controls,
  Info,
  InfoWrapper,
  InteractiveSection,
  Root,
  Title,
  Year,
  InfoTextDesktop,
  ButtonTextDesktop,
} from "./lot-header-block.styled";
import likeIcon from "../../images/icons/like-icon-dark-inactive.png";

export const LotHeaderBlock = () => {
  return (
    <Root>
      <NarrowContainer>
        <Year>1997</Year>
        <Title>Porsche 911 Turbo S</Title>
        <InteractiveSection>
          <InfoWrapper>
            <Info>
              <InfoTextDesktop>Bid: </InfoTextDesktop><b>$550,000</b>
            </Info>
            <Info>|</Info>
            <Info>
              <InfoTextDesktop>Ends in: </InfoTextDesktop><b>11:08:12</b>
            </Info>
          </InfoWrapper>
          <Controls>
            <Button>SHIPPING</Button>
            <Button>HOW IT WORKS</Button>
            <Button>
              <img src={likeIcon} alt="like" />
              <span><ButtonTextDesktop>WATCH</ButtonTextDesktop></span>
            </Button>
            <Button blue>PLACE BID</Button>
          </Controls>
        </InteractiveSection>
      </NarrowContainer>
    </Root>
  );
};
