import lot1 from "../images/lot1-image.jpg";
import lot2 from "../images/lot2-image.png";
import lot3 from "../images/lot3-image.png";
import lot4 from "../images/lot4-image.jpg";
import lot5 from "../images/lot5-image.jpg";
import lot6 from "../images/lot6-image.jpg";
import lot7 from "../images/lot7-image.jpg";
import lot8 from "../images/lot8-image.jpg";
import lot9 from "../images/lot9-image.jpg";

export const carouselsData = [
  {
    year: "1997",
    name: "Porsche 911 turbo S",
    text: "This 1989 Lamborghini Countach is one of approximately 658 examples of the 25th Anniversary model built during a three-year production run. The car was completed in July 1989 and was previously registered in New York, Texas, Florida, and Indiana before it was purchased by the seller in 2010. It is finished in black over matching leather...",
    bid: "$600,000",
    endsIn: "19:58:12",
    img: lot1,
    transmission: "automatic",
    bodyStyle: "coupe",
    mileage: "1000"
  },
  {
    year: "1987",
    name: "Mercedes-Benz 560 SEC AMG",
    text: "This 2005 Mercedes-Benz SLR McLaren is powered by a supercharged 5.4-liter M155 V8 paired with an AMG Speedshift five-speed automatic transmission and is finished in Crystal Laurite Silver over red Silver Arrow leather. Equipment includes 19 turbine-spoke alloy wheels, carbon-ceramic disk brakes, butterfly doors, xenon",
    bid: "$3,500,000",
    endsIn: "2:58:12",
    img: lot2,
    transmission: "automatic",
    bodyStyle: "sedan",
    mileage: "12000"
  },
  {
    year: "1996",
    name: "Bugatti EB110 GT",
    text: "This 2006 Ford GT is one of 2,011 built for the model year and is powered by  a supercharged 5,4-liter V8 linked with a six-speed manual gearbox and a helical limited-slip differential. The car remained with its original owner until the selling dealer's recent acquisition, and it now has 711 miles. It is finished in Mark IV Red over Ebony leather",
    bid: "$1,650,000",
    endsIn: "11:08:12",
    img: lot3,
    transmission: "manual",
    bodyStyle: "coupe",
    mileage: "760"
  },
  {
    year: "2002",
    name: "Ferrari Enzo",
    text: "This 1989 Lamborghini Countach is one of approximately 658 examples of the 25th Anniversary model built during a three-year production run. The car was completed in July 1989 and was previously registered in New York, Texas, Florida, and Indiana before it was purchased by the seller in 2010. It is finished in black over matching leather...",
    bid: "$600,000",
    endsIn: "19:58:12",
    img: lot4,
    transmission: "manual",
    bodyStyle: "coupe",
    mileage: "100000"
  },
  {
    year: "2021",
    name: "Bugatti Chiron Super Sport",
    text: "This 2005 Mercedes-Benz SLR McLaren is powered by a supercharged 5.4-liter M155 V8 paired with an AMG Speedshift five-speed automatic transmission and is finished in Crystal Laurite Silver over red Silver Arrow leather. Equipment includes 19 turbine-spoke alloy wheels, carbon-ceramic disk brakes, butterfly doors, xenon",
    bid: "$3,500,000",
    endsIn: "2:58:12",
    img: lot5,
    transmission: "automatic",
    bodyStyle: "coupe",
    mileage: "600"
  },
  {
    year: "2002",
    name: "Mercedes-Benz SLR McLaren",
    text: "This 2006 Ford GT is one of 2,011 built for the model year and is powered by  a supercharged 5,4-liter V8 linked with a six-speed manual gearbox and a helical limited-slip differential. The car remained with its original owner until the selling dealer's recent acquisition, and it now has 711 miles. It is finished in Mark IV Red over Ebony leather",
    bid: "$1,650,000",
    endsIn: "11:08:12",
    img: lot6,
    transmission: "manual",
    bodyStyle: "suv",
    mileage: "80000"
  },
  {
    year: "1989",
    name: "Lamborghini Countach",
    text: "This 1989 Lamborghini Countach is one of approximately 658 examples of the 25th Anniversary model built during a three-year production run. The car was completed in July 1989 and was previously registered in New York, Texas, Florida, and Indiana before it was purchased by the seller in 2010. It is finished in black over matching leather...",
    bid: "$600,000",
    endsIn: "19:58:12",
    img: lot7,
    transmission: "automatic",
    bodyStyle: "coupe",
    mileage: "60000"
  },
  {
    year: "2015",
    name: "Ferrari TDF",
    text: "This 2005 Mercedes-Benz SLR McLaren is powered by a supercharged 5.4-liter M155 V8 paired with an AMG Speedshift five-speed automatic transmission and is finished in Crystal Laurite Silver over red Silver Arrow leather. Equipment includes 19 turbine-spoke alloy wheels, carbon-ceramic disk brakes, butterfly doors, xenon",
    bid: "$3,500,000",
    endsIn: "2:58:12",
    img: lot8,
    transmission: "automatic",
    bodyStyle: "coupe",
    mileage: "28000"
  },
  {
    year: "1988",
    name: "Ferrari F40 'Competizione'",
    text: "This 2006 Ford GT is one of 2,011 built for the model year and is powered by  a supercharged 5,4-liter V8 linked with a six-speed manual gearbox and a helical limited-slip differential. The car remained with its original owner until the selling dealer's recent acquisition, and it now has 711 miles. It is finished in Mark IV Red over Ebony leather",
    bid: "$1,650,000",
    endsIn: "11:08:12",
    img: lot9,
    transmission: "automatic",
    bodyStyle: "coupe",
    mileage: "1000"
  },
  {
    year: "2021",
    name: "Ferrari Roma",
    text: "Ferrari Westlake is excited to offer the availability of this one-owner, low-mileage 2021 Ferrari Roma finished in Grigio Alloy over a Nero leather interior with tasteful accents of alcantara. The Roma is the modern take on the iconic Ferrari 250 Lusso from the 1960's. This 2+2 GT Coupe has the design language that inspires La Nuova Dolce Vita, Italian for the pleasurable way of life.",
    bid: "$299,000",
    endsIn: "09:02:21",
    img: lot1,
    transmission: "manual",
    bodyStyle: "coupe",
    mileage: "2125"
  },
  {
    year: "2005",
    name: "Mercedes-Benz CLK-Class",
    text: "2005 MERCEDES-BENZ CLK320 CABRIOLET!!!!!! ACCIDENT-FREE CARFAX!!!!!! EQUIPMENT INCLUDES A BLACK SOFT TOP, 17 FIVE-SPOKE WHEELS, POWER-ADJUSTABLE FRONT SEATS W/ MEMORY SETTINGS, FOG LIGHTS, BURL WOOD TRIM, DUAL-ZONE ELECTRONIC CLIMATE CONTROL, AND MUCH MORE!!!!! FOR MORE INFORMATION ON THIS VEHICLE, PLEASE EMAIL OR CALL *number in the description*. THANKS FOR LOOKING!!!!!..",
    bid: "$550,000",
    endsIn: "23:10:25",
    img: lot2,
    transmission: "automatic",
    bodyStyle: "suv",
    mileage: "29064"
  },
  {
  year: "2014",
  name: "Ferrari F12 Berlinetta",
  text: "Ferrari Westlake is excited to present this beautiful 2014 Ferrari F12 Berlinetta, finished in a tasteful colorway bearing a Nero exterior over a Cuoio leather interior, accented by some timeless options. With its 5.3L V12 powerplant, you become the conductor of a symphony of exotic, spine-tingling exhaust notes.",
  bid: "$375,000",
  endsIn: "18:31:55",
  img: lot3,
  transmission: "CVT",
  bodyStyle: "hatchback",
  mileage: "9228"
  },
  {
  year: "2017",
  name: "Dodge Viper",
  text: "Recent Arrival! ACR Badge, ACR Console, ACR Door Trim Panel, ACR Floor Mats, ACR Instrument Panel, ACR Interior Package (DISC), ACR Package (DISC), Aero Wing Accessory Kit - Exposed Weave, Alcantara Wrap Steering Wheel, Anti-Lock 4-Wheel Disc Brakes, Black Calipers w/Orange Writing, Carbon Fiber Belly Pan, Custom Car Cover, Exterior...",
  bid: "$439,000",
  endsIn: "06:49:37",
  img: lot4,
  transmission: "robotic",
  bodyStyle: "sedan",
  mileage: "4902"
  },
  {
  year: "2019",
  name: "McLaren 600LT",
  text: "The 2019 McLaren 600LT is a popular car from McLaren and a strong competitor in its segment. The 2019 McLaren 600LT price offer included many premium options and standard features that makes this vehicle stand out. Inquire today and lock-in your 2019 McLaren 600LT price with the seller.",
  bid: "$791,000",
  endsIn: "17:35:02",
  img: lot5,
  transmission: "automatic",
  bodyStyle: "coupe",
  mileage: "25000"
  },
  {
  year: "2017",
  name: "Cadillac Escalade",
  text: "KBB.com 10 Best Luxury SUVs. Only 45,877 Miles! Boasts 20 Highway MPG and 15 City MPG! This Cadillac Escalade boasts a Gas V8 6.2L/376 engine powering this Automatic transmission. WHEELS, 22 X 9 (55.9 CM X 22.9 CM) 7-SPOKE ALUMINUM with premium paint and chrome inserts (STD), TRANSMISSION, 8-SPEED AUTOMATIC",
  bid: "$358,000",
  endsIn: "12:17:45",
  img: lot6,
  transmission: "CVT",
  bodyStyle: "suv",
  mileage: "45877"
  },
  {
  year: "2022",
  name: "Rolls-Royce Cullinan",
  text: "2022 ROLLS-ROYCE CULLINAN BLACK BADGEnbsp; SHOOTING STAR HEADLINER!! BLACK BADGE TECHNICAL CARBON!!! Painted in Black Diamond From Rolls-Royce Two-Tone Tan amp; Black Leather Interior w/ Black Badge Technical Carbon Extended Trimnbsp; Soft Grain Leather Steering Wheel With Technical Carbon Fiber Trim Rolls Royce Bespoke Audio Systemnbsp; Heated amp; Ventilated Front",
  bid: "$489,999",
  endsIn: "6:23:57",
  img: lot7,
  transmission: "robotic",
  bodyStyle: "hatchback",
  mileage: "10500"
  },
  {
  year: "1984",
  name: "Lancia LC2",
  text: "To Be OFFERED AT AUCTION at RM Sothebys' Le Mans event, 9 June 2023. Please note this lot has entered the EU on a temporary import bond, which must be cancelled either by exporting the lot outside of the EU on an approved Bill of Lading with supporting customs documentation",
  bid: "$150,000",
  endsIn: "09:05:02",
  img: lot9,
  transmission: "CVT",
  bodyStyle: "coupe",
  mileage: "35486"
  },
  {
  year: "2000",
  name: "Hummer H1",
  text: "6.5 Turbo Diesel upgraded with stage high output kit and 4 performance exhaust system . KN filter. Upgraded ceramic brakes. New CV joints and boots. New gear box. Center shaft carrier bearing.New power steering pump, new AC compressor and fan. new hoses and belts .Aluminium radiator .",
  bid: "$129,900",
  endsIn: "12:54:13",
  img: lot1,
  transmission: "robotic",
  bodyStyle: "suv",
  mileage: "45128"
  },
  {
  year: "1969",
  name: "Plymouth GTX",
  text: "This vehicle is being sold at the Glendale 2023 as Lot No. T118. - 5-year restoration completed in 2005- Upgraded with new 4-barrel carburetor- 4-speed manual transmission -- converted from original automatic transmission - New Silver Metallic paint - New Black interior - Aluminum radiator with electric fans",
  bid: "$422,589",
  endsIn: "14:52:44",
  img: lot3,
  transmission: "CVT",
  bodyStyle: "sedan",
  mileage: "326155"
  },
  {
  year: "1979",
  name: "BMW M1",
  text: "The M1 was the first mid-engine BMW to go into production, with the M1â€™s 24-valve twin-cam six also powering the late-â€™80s M5 sedan and M6 coupe. The motivation to build the M1 came from racing and a set of regulations prompted by the new Group Five in 1976. ",
  bid: "$799,000",
  endsIn: "23:51:41",
  img: lot4,
  transmission: "automatic",
  bodyStyle: "coupe",
  mileage: "469123"
  },
  {
  year: "1988",
  name: "Land Rover Defender",
  text: "This custom ECD Defender 110 build known as Project Big Tex! It is finished in Corris Grey eggshell and powered by a 6.2L LS3 V8 w/custom Hooker headers and it is paired to a 6 speed Automatic transmission. This Defender 110 has 20″ ECD custom wheels wrapped in BFGoodrich All-Terrain",
  bid: "$289,472",
  endsIn: "02:13:53",
  img: lot5,
  transmission: "manual",
  bodyStyle: "suv",
  mileage: "245831"
  },
  {
  year: "2017",
  name: "Maserati GranTurismo",
  text: "2017 Maserati GranTurismo MC, S/N ZAM45vLA4H0248473, Bianco Eldorado with red leather. A true “MC” optioned GranTurismo with Scuderia-style rear exhausts, carbon vented hood, side vents, MC rims and updated carbon pieces, tuning and software. Not to be compared to “MC Sportline” which was just a pretty-up package, no functionality",
  bid: "$492,588",
  endsIn: "09:32:35",
  img: lot6,
  transmission: "robotic",
  bodyStyle: "hatchback",
  mileage: "18930"
  },
  {
  year: "1950",
  name: "Crosley Hotshot",
  text: "1950 Crosley Hotshot Super, S/N 20382, Yellow with red interior. The Crosley line of automobiles from Cincinnati, Ohio was more impressive than their small stature would imply…they held many automotive “firsts” and for a company that only sold cars from 1938 to 1952, they made a big impact in the automotive world. The first American car company to have an affordable, mass market over-head camshaft engine, first company to use the phrase “sport utility” and the first American car company to have 4 wheel disc brakes on a production car.",
  bid: "$173,548",
  endsIn: "19:13:56",
  img: lot7,
  transmission: "manual",
  bodyStyle: "sedan",
  mileage: "145798"
  },
  {
  year: "2020",
  name: "Alfa Romeo Giulia",
  text: "The 2020 Alfa Romeo Giulia is a popular Sedan from Alfa Romeo and a strong competitor in its segment. The 2020 Alfa Romeo Giulia price offer included many premium options and standard features that makes this vehicle stand out. Inquire today and lock-in your 2020 Alfa Romeo Giulia price with seller.",
  bid: "$542,639",
  endsIn: "17:54:01",
  img: lot8,
  transmission: "robotic",
  bodyStyle: "coupe",
  mileage: "236594"
  },
  {
  year: "2022",
  name: "Apocalypse Hellfire",
  text: "Several in the pipe line, inquire and ask for Cyrus! This Apocalypse Hellfire in a T H R E E part K E V L A R protective coating exterior Finish, Cuoio on Nero The Bull Huracan Evo Style Diamond Stitching, with Matte Gold Wheels. These 6x6 are engineered with three killer choices of powerplants and 3 truck type models. This Jaw dropping truck can be seen at Euro Prestige Imports. ",
  bid: "$1,458,267",
  endsIn: "01:42:09",
  img: lot9,
  transmission: "CVT",
  bodyStyle: "suv",
  mileage: "1479564"
  },
  {
  year: "1971",
  name: "Daimler DS420",
  text: "This vehicle is being sold at the Glendale 2023 as Lot No. W55. - White with original Brown interior- Air conditioning- Power steering- New tires The 1971 Daimler DS420 is a popular Limousine from Daimler and a strong competitor in its segment. The 1971 Daimler DS420 price offer included many premium options and standard features that makes this vehicle stand out.",
  bid: "$216,199",
  endsIn: "31:05:39",
  img: lot1,
  transmission: "manual",
  bodyStyle: "hatchback",
  mileage: "45136"
  },
  {
  year: "2001",
  name: "Ferrari 550 Barchetta",
  text: "Just 5,007 miles from new Service by Ferrari of Los Angeles in September 2021 at the cost of $15,782.78 Five-Piece luggage set, books, and tools included The modern classic fair weather Ferrari One 133 550 Barchetta destined for the United States The 2001 Ferrari 550 Barchetta is a popular car from Ferrari and a strong competitor in its segment. The 2001 Ferrari 550 Barchetta price offer included many premium options and standard features that makes this vehicle stand out. Inquire today and lock-in your 2001 Ferrari 550 Barchetta price",
  bid: "$625,000",
  endsIn: "06:01:00",
  img: lot2,
  transmission: "automatic",
  bodyStyle: "sedan",
  mileage: "37915"
  }
];
