import styled from "styled-components";

export const Root = styled.div`
  @media (max-width: 800px) {
    padding: 32px 15px 32px 15px;
    max-width: none;
    min-width: auto;
    width: 375px;
    flex-wrap: wrap;
  }

  padding: 110px 0;
  background: url(${(props) => props.img}) center no-repeat;
  background-size: cover;
  min-width: 1440px;
  max-width: 1920px;
`;

export const SearchText = styled.div`
  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
    margin: 0 auto 28px auto;
  }

  font-weight: 500;
  font-size: 33px;
  line-height: 32px;
  max-width: 500px;
  margin: 0 auto 40px auto;
  text-align: center;
`;

export const SearchWrapper = styled.div`
  @media (max-width: 800px) {
    display: block;
  }

  display: flex;
  gap: 20px;
  justify-content: center;

  & > button {
    @media (max-width: 800px) {
      width: 100%;
      height: 37px;
      padding: 0;
      font-size: 12px;
    }

    color: #326edb;
    cursor: pointer;
    width: 134px;
    font-size: 17px;
    font-weight: 700;
    padding: 0 15px;
    background-color: inherit;
    border: 1px solid black;
    border-radius: 4px;
  }
`;

export const SearchInput = styled.input`
  @media (max-width: 800px) {
    height: 40px;
    padding: 0;
    width: 100%;
    margin-bottom: 8px;
    text-align: center;
    font-size: 14px;
  }

  border: none;
  width: 434px;
  padding: 13px 23px;
  font-size: 17px;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;

  &:focus-visible {
    outline: none;
  }
`;
