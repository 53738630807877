import React from "react";
import {
  Root,
  Copyrights,
  Text,
  TextRed,
  TextUnderlined,
  FooterWrapper,
  FooterItem,
  TextBold,
  FooterSocials,
  FooterLogo,
  PartnersWrapper,
  CopyrightsWrapper,
  CopyrightsMobileText,
} from "./footer.styled";
import logoDark from '../../images/logo-dark.svg'
import socialsLogos from "../../images/socials_logos.png";
import { Container } from "../../app.styled";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <Root>
      <Container>
        <FooterWrapper>
          <FooterItem>
            <FooterLogo>
              <img src={logoDark} alt="sotheby's black logo" />
            </FooterLogo>
            <FooterSocials>
              <img src={socialsLogos} alt="social icons" />
            </FooterSocials>
          </FooterItem>
          <PartnersWrapper>
            <FooterItem>
              <TextBold>Sotheby's motor</TextBold>
              <Text>
                <Link to="/about-us">About Us</Link>
              </Text>
              <Text>
                <Link to="/submit-vehicle">Submit Vehicle</Link>
              </Text>
              <Text>
                <Link to="/news">News</Link>
              </Text>
              <Text>
                <Link to="/Login">Login</Link>
              </Text>
            </FooterItem>
            <FooterItem>
              <TextBold>Motorsport Network</TextBold>
              <Text>
                <a href="/">Motorsport.com</a>
              </Text>
              <Text>
                <a href="/">Motorsport.tv</a>
              </Text>
              <Text>
                <a href="/">Motor1.com</a>
              </Text>
              <Text>
                <a href="/">Dupontregistry.com</a>
              </Text>
              <Text>
                <a href="/">Canossa.com</a>
              </Text>
              <Text>
                <a href="/">Cavallino.com</a>
              </Text>
            </FooterItem>
            <FooterItem>
              <TextBold>Contact Us</TextBold>
              <Text>
                Toll Free <TextUnderlined>+1 800 233 1731</TextUnderlined>
              </Text>
              <Text>
                International <TextUnderlined>+1 305 507 8799</TextUnderlined>
              </Text>
              <TextUnderlined>5972 NE 4th Avenue</TextUnderlined>
              <TextUnderlined>Miami, FL 33137</TextUnderlined>
              <TextUnderlined>USA</TextUnderlined>
            </FooterItem>
          </PartnersWrapper>
        </FooterWrapper>
      </Container>

      <CopyrightsWrapper>
        <Container>
          <Copyrights>
            <div>
              &#169;<Text>{new Date().getFullYear()}{' '}<CopyrightsMobileText>
              Sotheby’s |
            </CopyrightsMobileText>
            </Text>
              <TextRed>{' '}Motorsport Network</TextRed>
              <Text> All Rights Reserved.</Text>
            </div>
            <div>
              <Text>
                <a href="/">Terms & Conditions</a>
              </Text>
              <Text>
                <a href="/">Privacy Policy</a>
              </Text>
            </div>
          </Copyrights>
        </Container>
      </CopyrightsWrapper>
    </Root>
  );
};
