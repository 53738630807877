import styled from "styled-components";
import { InputWrapper } from "../_home-page/header-with-slider-block/header-with-slider-block.styled";

export const FooterText = styled.span`
  white-space: nowrap;

  & > a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    white-space: nowrap;
  }
`;

export const FooterTextRed = styled.span`
  color: red;
`;

export const FooterSocials = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: space-between;

  & > img {
    width: 250px;
  }
`;

export const Root = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  background-color: white;
  padding: 12px 15px;
  overflow: scroll;
  width: 375px;
  transform: translateX(-50%);
`;

export const CloseIcon = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  align-self: center;

  & > img {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled.div`
  width: 210px;
  height: 32px;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  padding: 0 20px;
  margin-top: 35px;

  ${InputWrapper} {
    display: block;

    & > input {
      width: 100%;
      height: 40px;
      font-size: 17px;
    }

    & > div {
      background-size: cover;
    }
  }
`;

export const Menu = styled.div`
  margin-top: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
`;

export const LogInIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 22px;
  margin-right: 12px;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const WatchIcon = styled.div`
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 10px;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const MenuItem = styled.div`
  font-size: 30px;
  font-weight: 700;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  & > a {
    text-decoration: none;
    color: black;
  }
`;

export const SubMenu = styled.div`
  margin-top: 20px;
`;

export const SubMenuItem = styled.div`
  font-size: 20px;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  & > a {
    text-decoration: none;
    color: black;
  }
`;

export const Copyrights = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 15px;
  padding: 12px 0;

  & > div {
    &:nth-of-type(2) {
      @media (max-width: 800px) {
        margin-bottom: 22px;
      }

      & > span {
        &:first-of-type {
          margin-right: 40px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;

    ${FooterText} {
      display: inline-block;
      line-height: 20px;
    }
  }
`;

export const CopyrightsWrapper = styled.div`
  margin-top: 10px;

`;

export const CopyrightsMobileText = styled.span`
  display: none;

  @media (max-width: 800px) {
    display: inline;
  }
`;

