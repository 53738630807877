import React from "react";
import {
  Root,
  Header,
  Logo,
  CloseIcon,
  Wrapper,
  Menu,
  SubMenu,
  SubMenuItem,
  MenuItem,
  WatchIcon,
  LogInIcon,
  Copyrights,
  CopyrightsWrapper,
  CopyrightsMobileText,
  FooterText,
  FooterTextRed,
  FooterSocials,
} from "./main-header-mobile-menu.styled";
import logoDarkPng from "../../images/logo-dark.png";
import logo from "../../images/logo.svg";
import closeIcon from "../../images/_lot-page/closeIcon.png";
import { Link } from "react-router-dom";
import { InputWrapper } from "../_home-page/header-with-slider-block/header-with-slider-block.styled";
import logInIcon from "../../images/_lot-page/log-in-icon.png";
import watchIcon from "../../images/_lot-page/watch-icon.png";
import { Container } from "../../app.styled";
import socialsIcons from "../../images/social_icons.svg";

export const MainHeaderMobileMenu = (props) => {
  return (
    <Root>
      <Header>
        <Logo>
          {/* <img src={props.dark ? logoDarkPng : logo} alt="logo" /> */}
        </Logo>
        <CloseIcon onClick={props.close()}>
          <img src={closeIcon} alt="closeIcon" />
        </CloseIcon>
      </Header>
      <Wrapper>
        {/* <InputWrapper dark={props.dark}>
          <input placeholder="Search for make, model, category" />
          <div />
        </InputWrapper> */}
        <Menu>
          <MenuItem>
            <Link to="/">
               {/*<LogInIcon>
                <img src={logInIcon} alt="logInIcon" />
              </LogInIcon>*/}
              LOG IN
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">GET ALERTS</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">HOW IT WORKS</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">
              {/*<WatchIcon>
                <img src={watchIcon} alt="watchIcon" />
              </WatchIcon> */}
              MY WATCH LIST
            </Link>
          </MenuItem>
        </Menu>
        <SubMenu>
          <MenuItem>
            <Link to="/">AUCTIONS</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">SUBMIT A VEHICLE</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">MAKE AND MODELS</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">CATEGORIES</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">SHIPPING</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">FINANCE</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">EVENT CALENDAR</Link>
          </MenuItem>          
        </SubMenu>

        <FooterSocials>
          <img src={socialsIcons} alt="social icons" />
        </FooterSocials>

        <CopyrightsWrapper>
          <Container>
            <Copyrights>
              <div>
                &#169;<FooterText>2022{' '}<CopyrightsMobileText>Sotheby’s | </CopyrightsMobileText></FooterText>
                <FooterTextRed>{' '}Motorsport Network</FooterTextRed>
                <FooterText> All Rights Reserved.</FooterText>
              </div>
            </Copyrights>
          </Container>
        </CopyrightsWrapper>
      </Wrapper>
    </Root>
  );
};
