import {
  Content,
  LeftSide,
  RightSide,
  Root,
  Title,
  Wrapper,
} from "./control-panel.styled";
import { Container } from "../../../app.styled";
import logo from "../../../images/logo-dark.png";
import {
  Button,
  Controls,
  Info,
  InfoWrapper,
  InfoDesktop, ButtonTextDesktop,
} from "../lot-header-block.styled";
import likeIcon from "../../../images/icons/like-icon-dark-inactive.png";
import { useEffect, useState } from "react";

export const ControlPanel = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    setScrollY(window.scrollY);

    function onWindowScroll() {
      setScrollY(window.scrollY);
    }

    window.addEventListener("scroll", onWindowScroll);
    return () => {
      window.removeEventListener("scroll", onWindowScroll);
    };
  }, []);

  if (scrollY < 170) return null;

  return (
    <Root>
      <Wrapper>
        <Container>
          <Content>
            <LeftSide>
              <img src={logo} alt="logo" width={206} />
              <Title>
                <div>1997</div>
                <div>Prosche 911 Turbo S</div>
              </Title>
            </LeftSide>
            <RightSide>
              <InfoWrapper>
                <Info>
                  <InfoDesktop>Bid: </InfoDesktop> <b>$550,000</b>
                </Info>
                <Info>|</Info>
                <Info>
                  <InfoDesktop>Ends in</InfoDesktop> <b>11:08:12</b>
                </Info>
              </InfoWrapper>
              <Controls>
                <Button>SHIPPING</Button>
                <Button>HOW IT WORKS</Button>
                <Button>
                  <img src={likeIcon} alt="like" />
                  <span><ButtonTextDesktop>WATCH</ButtonTextDesktop></span>
                </Button>
                <Button blue>PLACE BID</Button>
              </Controls>
            </RightSide>
          </Content>
        </Container>
      </Wrapper>
    </Root>
  );
};
