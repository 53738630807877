import styled from "styled-components";

export const Root = styled.div``;

export const Wrapper = styled.div`
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  border-top: 1px solid lightgray;
  background-color: white;
  z-index: 1;
  box-shadow: 0 0 2px black;

  @media (max-width: 800px) {
    height: 60px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;

  @media (max-width: 800px) {
    height: 60px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 34px;
  align-items: center;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const RightSide = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 800px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const Title = styled.div`
  > div:first-child {
    font-size: 17px;
    line-height: 17px;
  }

  > div:last-child {
    font-size: 26px;
    line-height: 27px;
  }
`;
