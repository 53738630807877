import styled from "styled-components";

export const Root = styled.div`
  min-width: 1440px;
  margin: 0 auto;

  @media (max-width: 800px) {
    min-width: auto;
  }
`;

export const Item = styled.div`
  width: calc((100% - 40px) / 3);
  background-color: #f7f7f7;
  overflow: hidden;
  border-radius: 4px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const CarouselsWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  text-align: left;
`;

export const CarouselItemWrapper = styled.div`
  background-color: #f7f7f7;
  padding: 16px 20px;
  position: relative;

  > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const CarouselImage = styled.div`
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ImageLike = styled.img`
  width: 17px;
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
`;

export const CarouselBidWrapper = styled.div`
  display: flex;
`;

export const CarouselBid = styled.div`
  display: flex;
  gap: 5px;

  &:first-of-type {
    margin-right: 10px;
  }

  &:last-of-type {
    margin-left: 10px;
  }
`;

export const CarouselBidText = styled.div`
  font-weight: bold;
`;

export const CarouselYear = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: grey;
  margin-bottom: 6px;
`;

export const CarouselText = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 22px;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 5;
           line-clamp: 5; 
   -webkit-box-orient: vertical;
`;

export const CarouselName = styled.div`
  font-size: 23px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 17px;
`;

export const SwitchCarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 180px;
  margin-top: 45px;
  align-items: center;
  white-space: nowrap;

  @media (max-width: 800px) {
    justify-content: space-between;
    gap: unset;
  }
`;

export const SwitchText = styled.div`
  font-size: 15px;
  color: black;
`;
