import ferrariImage from "../images/slider/slider_ferrari.jpeg";
import mercedesImage from "../images/slider/slider_mercedes.jpeg";
import porscheImage from "../images/slider/slider_porsche.jpeg";
import ferrariMobileImage from "../images/slider/slider-grey-ferrari.jpg";
import mercedesMobileImage from "../images/_home-page/slider-mercedes.png";
import porscheMobileImage from "../images/_home-page/slider-porsche.png";

export const sliderData = [
  {
    image: ferrariImage,
    imageMobile: ferrariMobileImage,
    description: {
      year: "1994",
      name: "Ferrari",
      model: "F512 M",
    },
  },
  {
    image: mercedesImage,
    imageMobile: mercedesMobileImage,
    description: {
      year: "2007",
      name: "Mercedes-Benz",
      model: "SLR McLaren 722 Edition",
    },
  },
  {
    image: porscheImage,
    imageMobile: porscheMobileImage,
    description: {
      year: "2005",
      name: "Porsche",
      model: "Carrera GT",
    },
  },
];
