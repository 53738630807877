import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { HomePage } from "./pages/home-page";
import { LotPage } from "./pages/lot-page";
import { ComingSoon } from "./pages/coming-soon";

const router = createHashRouter([
  
  {
    path: "/about-us",
    element: <ComingSoon />,
  },
  {
    path: "/submit-vehicle",
    element: <ComingSoon />,
  },
  {
    path: "/news",
    element: <ComingSoon />,
  },
  {
    path: "/login",
    element: <ComingSoon />,
  },
  {
    path: "/",
    element: <HomePage />,
    
  },
  {
    path: "/lots/:id",
    element: <LotPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App>
      <RouterProvider router={router} />
    </App>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function scaleBody() {
  let zoom = 1;

  if (window.innerWidth <= 800) {
    zoom = (window.innerWidth * 100) / 375 / 100;
  } else if (window.innerWidth > 1920) {
    zoom = (window.innerWidth * 100) / 1920 / 100;
  }

  document.body.style.zoom = zoom;
}

window.addEventListener("resize", scaleBody);

setTimeout(() => scaleBody(), 100);
scaleBody();
