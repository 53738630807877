import styled from "styled-components";
import { Button } from "../../blocks/_lot-page/lot-header-block.styled";

export const Root = styled.div`
  margin-top: 45px;
  border-radius: 4px;
  background-color: #f7f7f7;
  padding: 25px;

  @media (min-width: 1439px) {
    display: none;
  }
`;

export const Title = styled.div`
  font-size: 22px;
`;

export const Text = styled.div`
  font-size: 18px;

  &:nth-of-type(odd) {
    margin-bottom: 15px;
  }

  &:nth-of-type(even) {
    margin-bottom: 30px;
    line-height: 22px;
  }

  &:last-of-type {
    margin-bottom: 0;

    ${Button} {
      display: block;
      text-align: center;
      padding: 10px;
      font-size: 14px;
    }
  }
`;

export const TextWrapper = styled.div`
  margin-top: 30px;
`;

export const Footer = styled.div`
  border-top: 1px solid black;
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const FooterSection = styled.div`
  color: gray;
  display: flex;
  gap: 19px;
  font-size: 14px;
  margin-top: 23px;
`;
