import styled from "styled-components";
import { Button } from "../../blocks/_lot-page/lot-header-block.styled";

export const Root = styled.div`
  margin-top: 10px;

  > textarea {
    font-family: "Open Sans", sans-serif;
    padding: 10px 16px;
    font-size: 14px;
    width: 100%;

    @media (max-width: 800px) {
      padding: 12px 16px 24px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  div:first-child {
    color: gray;
  }

  ${Button} {
    text-align: center;
    justify-content: center;
    width: 100px;
  }

  @media (max-width: 800px) {
    margin-top: 12px;

    div:first-child {
      font-size: 12px;
    }

    ${Button} {
      flex-shrink: 0;
      padding: 7px 12px;
      font-size: 13px;
      min-width: 107px;
    }
  }
`;
