import styled from "styled-components";
import { Container } from "../../app.styled";

export const Root = styled.div`
  @media (max-width: 800px) {
    padding: 0 15px;
    max-width: none;
    min-width: auto;
    width: 375px;
    margin-top: 35px;

    ${Container} {
      padding: 0;
      width: auto;
    }
  }

  margin-top: 40px;
  min-width: 1440px;
`;

export const Text = styled.span`
  white-space: nowrap;

  & > a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    white-space: nowrap;
  }
`;

export const TextRed = styled.span`
  color: red;
`;

export const TextUnderlined = styled.span`
  text-decoration: underline;
  white-space: nowrap;
`;

export const TextBold = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
`;

export const CopyrightsWrapper = styled.div`
  margin-top: 70px;

  @media (max-width: 800px) {
    margin-top: 25px;
  }
`;

export const CopyrightsMobileText = styled.span`
  display: none;

  @media (max-width: 800px) {
    display: inline;
  }
`;

export const Copyrights = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 15px;
  padding: 12px 0;
  border-top: 1px solid grey;

  & > div {
    &:nth-of-type(2) {
      @media (max-width: 800px) {
        margin-bottom: 22px;
      }

      & > span {
        &:first-of-type {
          margin-right: 40px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 21px 0;

    ${Text} {
      display: inline-block;
      line-height: 20px;
    }
  }
`;

export const FooterWrapper = styled.div`
  @media (max-width: 800px) {
    display: block;
  }

  display: flex;
  text-align: left;
  justify-content: space-between;
`;

export const PartnersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 160px;

  @media (max-width: 800px) {
    gap: 48px;
    flex-wrap: wrap;
  }
`;

export const FooterItem = styled.div`
  @media (max-width: 800px) {
    &:first-of-type {
        max-width: 70%;
        margin: 0 auto 37px auto;
    }
  }

  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const FooterLogo = styled.div`
  max-width: 250px;
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    max-width: none;
    justify-content: center;
  }
`;

export const FooterSocials = styled.div`
  @media (max-width: 800px) {
    display: none;
  }

  margin-top: 17px;
  display: flex;
  justify-content: space-between;

  & > img {
    width: 365px;
    margin-right: 100px;
  }
`;
