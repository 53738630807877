import styled from "styled-components";
import { Button } from "../../blocks/_lot-page/lot-header-block.styled";

export const Root = styled.div`
  display: none;
  margin-top: 25px;
  border-radius: 4px;
  background-color: #f7f7f7;
  padding: 22px 27px 22px 27px;

  @media (min-width: 1439px) {
    display: block;
  }
`;

export const Title = styled.div`
  font-size: 18px;
`;

export const Table = styled.table`
  font-size: 15px;
  margin-left: -3px;
  margin-top: 10px;
  width: 509px;

  td:first-child {
    padding-right: 28px;
  }

  td {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  ${Button} {
    justify-content: center;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid black;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`;

export const FooterSection = styled.div`
  color: gray;
  display: flex;
  gap: 14px;
  font-size: 13px;
  margin-top: 14px;
`;
