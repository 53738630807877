import styled from "styled-components";

export const Root = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 1330px;
  z-index: 1;

  @media (max-width: 800px) {
    width: calc(375px - 31px);
    max-width: none;
    min-width: auto;
  }
`;

export const NarrowContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 855px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const WideContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1920px;
  min-width: 1440px;

  @media (max-width: 1440px) {
    padding: 0 15px;
    max-width: none;
    min-width: auto;
  }
`;
