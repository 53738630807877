import styled from "styled-components";

export const Root = styled.div`
  padding: 57px 30px 30px;
  min-width: 1440px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const RootMobile = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: block;
    margin-top: 40px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  white-space: nowrap;
`;

export const HeaderLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
`;

export const Title = styled.div`
  font-size: 25px;
  line-height: 20px;
`;

export const Item = styled.div`
  text-align: left;
  display: flex;
  height: 140px;
  overflow: hidden;
  border-radius: 4px;
  width: 100%;

  @media (max-width: 800px) {
    height: 110px;
  }
`;

export const ItemImage = styled.div`
  & > img {
    height: 100%;
  }
`;

export const ItemWrapper = styled.div`
  background-color: #f7f7f7;
  padding: 20px 15px;

  & > div:nth-of-type(2) {
    margin-bottom: 10px;
  }

  @media (max-width: 800px) {
    padding: 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 25px;
`;

export const ItemsWrapper = styled.div`
  max-width: 40%;
  gap: 23px;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-top: 15px;
    margin-bottom: 20px;
    gap: 15px;
    max-width: none;
  }
`;

export const MainItem = styled.div`
  display: flex;
  position: relative;
  max-width: 60%;
  overflow: hidden;
  border-radius: 4px;

  @media (max-width: 800px) {
    max-width: none;
    width: 100%;
    border-radius: 0;
  }
`;

export const MainItemWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  left: 27px;
  text-align: left;

  @media (max-width: 800px) {
    left: 15px;
  }
`;

export const MainItemImage = styled.div`
  display: flex;

  & > img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
  }

  @media (max-width: 800px) {
    height: 375px;

    & > img {
      height: 100%;
      max-width: none;
      border-radius: 0;
    }
  }
`;

export const MainItemDate = styled.div`
  margin-bottom: 27px;
  font-size: 15px;
  line-height: 15px;
  color: white;

  @media (max-width: 800px) {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 0;
  }
`;

export const MainItemTitle = styled.div`
  font-size: 27px;
  line-height: 30px;
  color: white;

  @media (max-width: 800px) {
    font-size: 15px;
    line-height: 25px;
  }
`;

export const ItemDate = styled.div`
  font-size: 13px;
  line-height: 16px;

  @media (max-width: 800px) {
    font-size: 10px;
    line-height: 10px;
  }
`;

export const ItemTitle = styled.div`
  font-size: 16px;

  @media (max-width: 800px) {
    font-size: 10px;
    line-height: 10px;
  }
`;

export const ItemText = styled.div`
  font-size: 20px;
  line-height: 23px;

  @media (max-width: 800px) {
    font-size: 13px;
    line-height: 18px;
  }
`;
