import React from "react";
import {
  CarouselsWrapper,
  Root,
  SwitchCarouselWrapper,
  SwitchText,
} from "./carousel.styled";
import { CarouselItem } from "./carousel-item";
import arrowLeft from "../../../images/icons/arrow-left.png";
import arrowRight from "../../../images/icons/arrow-right.png";
import { Container } from "../../../app.styled";

export const Carousels = ({ items }) => {
  return (
    <Root>
      <Container>
        <CarouselsWrapper>
          {items.map((item, index) => {
            return (
              <CarouselItem index={[index, item.text].join("")} item={item} />
            );
          })}
        </CarouselsWrapper>
        <SwitchCarouselWrapper>
          <img height="35" src={arrowLeft} alt="arrow left" />
          <SwitchText>1 of 8</SwitchText>
          <img height="35" src={arrowRight} alt="arrow right" />
        </SwitchCarouselWrapper>
      </Container>
    </Root>
  );
};
