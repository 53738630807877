import { Link, Root, Title, Top } from "./gallery.styled";

export const Gallery = (props) => {
  return (
    <Root>
      <Top>
        <Title>
          {props.title} {props.count && <span>({props.count})</span>}
        </Title>
        {props.exploreAll ? (
          <Link href="src/components/gallery/gallery#">Explore all</Link>
        ) : (
          <span></span>
        )}
      </Top>
      {props.children}
    </Root>
  );
};

export { GalleryImages } from "./gallery.styled";
