import "./App.css";
import { Root } from "./app.styled";

function App(props) {
  return (
    <Root className="App" data-qa="Root">
      {props.children}
    </Root>
  );
}

export default App;
