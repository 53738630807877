import styled from "styled-components";
import searchIconSvg from "../../../images/search.svg";
import searchIconDarkPng from "../../../images/search-icon-dark.png";

export const Root = styled.div`
  min-width: 1440px;
  height: 700px;
  position: relative;

  @media (max-width: 800px) {
    min-width: auto;
    height: 590px;
  }
`;

export const ReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & > img {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
`;

export const ReviewTextBold = styled.div`
  font-weight: bold;
  color: white;
  font-size: 15px;
  margin-bottom: 10px;
`;

export const ReviewBlockRoot = styled.div`
  border-radius: 4px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  text-align: left;
  padding: 15px;
  width: 230px;

  @media (max-width: 800px) {
    transform: none;
    top: auto;
    right: auto;
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    padding: 0;
    width: auto;

    > ${ReviewTextBold} {
      font-size: 20px;
    }
  }
`;

export const ReviewYear = styled.div`
  color: white;
  font-size: 14px;

  @media (max-width: 800px) {
    font-size: 17px;
  }
`;

export const Delimiter = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 5px;
  background-color: white;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const DelimiterMobile = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: block;
    color: white;
    text-align: center;
  }
`;

export const ReviewText = styled.div`
  font-size: 15px;
  color: white;
`;

export const ReviewTextRed = styled.div`
  color: #0c70e3;
  font-size: 15px;
  font-weight: bold;
`;

export const ReviewLink = styled.a`
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  text-decoration: none;
  padding: 3px 0;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const HeaderBlock = styled.div`
  @media (max-width: 800px) {
    gap: 106px;
    justify-content: flex-start;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 1830px;
  margin: 0 auto;
  height: 60px;
`;

export const InputWrapper = styled.div`
  @media (max-width: 800px) {
    display: none;
  }

  position: relative;

  & > input {
    font-size: 15px;
    width: 500px;
    height: 30px;
    background-color: transparent;
    border: 1px solid
      ${(props) =>
        props.dark ? "rgba(0, 0, 0, 0.7)" : "rgba(255, 255, 255, 0.7)"};
    color: ${(props) =>
      props.dark ? "rgba(0, 0, 0, 0.7)" : "rgba(255, 255, 255, 0.7)"};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 15px;

    &:focus-visible {
      outline: none;
    }
  }

  & > div {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 5px;
    right: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 17px auto;
    background-image: url(${(props) =>
      props.dark ? searchIconDarkPng : searchIconSvg});
  }
`;

export const HeaderBlockMenu = styled.div`
  @media (max-width: 800px) {
    display: none;
  }

  & > img {
    margin-right: 30px;
    width: 15px;
  }

  & > a {
    text-decoration: none;
    color: ${(props) => (props.dark ? "black" : "white")};
    font-size: 15px;
    font-weight: bold;

    &:not(:last-of-type) {
      margin-right: 30px;
    }
  }
`;

export const BurgerWrapper = styled.div`
  @media (max-width: 800px) {
    display: block;
    width: 26px;
    height: 20px;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  display: none;
`;

export const Logo = styled.div`
  width: 210px;

  @media (min-width: 1439px) {
    width: 240px;
    margin-left: -20px;
  }

  img,
  a {
    width: 100%;
  }
`;

export const SubMenu = styled.div`
  @media (max-width: 800px) {
    display: none;
  }

  position: relative;
  z-index: 1;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding: 7px 0;
  display: flex;
  justify-content: center;
  gap: 45px;
  min-width: 1440px;

  & > a {
    text-decoration: none;
    color: ${(props) => (props.dark ? "black" : "white")};
    font-size: 15px;
    cursor: pointer;
  }
`;

export const SliderItem = styled.div`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.img}) center no-repeat;
  background-size: cover;
  transition: background-image 0.3s ease-in-out;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const SliderItemMobile = styled(SliderItem)`
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;

export const SliderBlock = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
`;

export const DotsContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  gap: 20px;
  bottom: 20px;
  justify-content: center;

  @media (max-width: 800px) {
    bottom: 15px;
  }
`;

export const DotsItemWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 15px;
  cursor: pointer;
`;

export const DotsItem = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  height: 1.5px;
  background-color: ${(props) => (props.active ? "white" : "grey")};
`;
