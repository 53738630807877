import styled, { css } from "styled-components";
import searchIcon from "../../../images/search.svg";

export const Search = styled.input`
  display: inline-block;
  outline: none;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: none;
  border: 1px solid gray;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 12px 14px;
  font-size: 14px;
  position: relative;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: center right 10px;
  color: white;
`;

export const Root = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: block;
    position: relative;
    color: white;

    ${(props) =>
      props.fixed
        ? css`
            position: fixed;
            width: 100%;
            top: -1px;
            z-index: 2;
          `
        : ""}
  }
`;
