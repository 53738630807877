import React from "react";
import {
  Root,
  Header,
  HeaderLink,
  MainItem,
  MainItemImage,
  MainItemTitle,
  Title,
  MainItemDate,
  Wrapper,
  Item,
  ItemWrapper,
  MainItemWrapper,
  ItemsWrapper,
  ItemDate,
  ItemTitle,
  ItemText,
  RootMobile,
} from "./recent-features.styled";
import arrowRight from "../../../images/arrow_right_small.svg";
import recentMain from "../../../images/recent-features-main.jpg";
import recentImg1 from "../../../images/recent-item-1.jpg";
import recentImg2 from "../../../images/recent-item-2.jpg";
import recentImg3 from "../../../images/recent-item-3.jpg";
import { Container } from "../../../app.styled";

const ItemsData = [
  {
    date: "11.10.22",
    title: "Automotive History",
    text: "In the Moment: Greens of summer",
    img: recentImg1,
  },
  {
    date: "11.10.22",
    title: "Automotive History",
    text: "In the Moment: Greens of summer",
    img: recentImg2,
  },
  {
    date: "11.10.22",
    title: "People",
    text: "Daring but doomed, Ken Carter was Canada's",
    img: recentImg3,
  },
];

export const RecentFeatures = () => {
  function renderHeader() {
    return (
      <Header>
        <Title>RECENT FEATURES</Title>
        <HeaderLink href="/">
          Explore all
          <img src={arrowRight} alt="arrow right" />
        </HeaderLink>
      </Header>
    );
  }

  function renderMainItem() {
    return (
      <MainItem>
        <MainItemImage>
          <img src={recentMain} alt="poster" />
        </MainItemImage>
        <MainItemWrapper>
          <MainItemDate>11.10.22 Luxury</MainItemDate>
          <MainItemTitle>
            Perfomance, luxury, design, and craftsmanship,
            <br />
            the Buggatti new Bugatti W16 Mistral has it all.
          </MainItemTitle>
        </MainItemWrapper>
      </MainItem>
    );
  }

  function renderItems() {
    return (
      <ItemsWrapper>
        {ItemsData.map((item, index) => {
          return (
            <Item key={[index, item.text].join("")}>
              <img src={item.img} alt="item" />
              <ItemWrapper>
                <ItemDate>{item.date}</ItemDate>
                <ItemTitle>{item.title}</ItemTitle>
                <ItemText>{item.text}</ItemText>
              </ItemWrapper>
            </Item>
          );
        })}
      </ItemsWrapper>
    );
  }

  return (
    <>
      <Root>
        <Container>
          {renderHeader()}
          <Wrapper>
            {renderMainItem()}
            {renderItems()}
          </Wrapper>
        </Container>
      </Root>
      <RootMobile>
        <Container>{renderHeader()}</Container>
        {renderMainItem()}
        <Container>{renderItems()}</Container>
      </RootMobile>
    </>
  );
};
