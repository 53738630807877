import React, { useState } from "react";
import {
  Delimiter,
  ReviewHeader,
  ReviewLink,
  ReviewText,
  ReviewTextBold,
  ReviewTextRed,
  ReviewYear,
  ReviewBlockRoot,
  DelimiterMobile,
} from "../header-with-slider-block/header-with-slider-block.styled";
import likeImageActive from "../../../images/icons/like-active.png";
import likeImage from "../../../images/icons/like.png";
import { DvorSobak, Root } from "./review-block.styled";
import { WideContainer } from "../../../app.styled";

export const ReviewBlock = ({ data }) => {
  const [active, setActive] = useState(false);

  function handleLikeClick() {
    return () => {
      setActive(!active);
    };
  }

  return (
    <Root data-qa="ReviewBlock">
      <WideContainer>
        <ReviewBlockRoot>
          <ReviewHeader>
            <ReviewYear>{data.year}</ReviewYear>
            <img
              src={active ? likeImageActive : likeImage}
              onClick={handleLikeClick()}
              alt="like"
            />
          </ReviewHeader>
          <ReviewTextBold>
            {data.name}
            <br /> {data.model}
          </ReviewTextBold>
          <Delimiter />
          <DvorSobak>
            <div>
              <ReviewText>BID:</ReviewText>
              <ReviewTextRed>$1,507,000</ReviewTextRed>
            </div>
            <DelimiterMobile>|</DelimiterMobile>
            <div>
              <ReviewText>ENDS IN:</ReviewText>
              <ReviewTextBold>43 min, 22 sec</ReviewTextBold>
            </div>
          </DvorSobak>
          <ReviewLink href="/">VIEW LOT</ReviewLink>
        </ReviewBlockRoot>
      </WideContainer>
    </Root>
  );
};
