import { SubMenu } from "../_home-page/header-with-slider-block/header-with-slider-block.styled";
import React from "react";

export const NavigationBlock = (props) => {
  return (
    <SubMenu dark={props.dark}>
      <a href="/">Auctions</a>
      <a href="/">Submit a Vehicle</a>
      <a href="/">Make and Models</a>
      <a href="/">Categories</a>
      <a href="/">Shipping</a>
      <a href="/">Finance</a>
      <a href="/">Event Calendar</a>      
    </SubMenu>
  );
};

NavigationBlock.defaultProps = {
  dark: false,
};
