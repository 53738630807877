import styled, { css } from "styled-components";

export const Root = styled.div`
  @media (max-width: 800px) {
    margin-top: 12px;
    padding: 0 15px;
  }

  margin-top: 27px;
`;

export const Year = styled.div`
  @media (max-width: 800px) {
    font-size: 20px;
  }

  font-size: 17px;
`;

export const Title = styled.div`
  @media (max-width: 800px) {
    font-size: 26px;
    margin-top: 17px;
    border-bottom: 1px solid black;
    padding-bottom: 30px;
  }

  font-size: 26px;
  margin-top: 10px;
`;

export const InteractiveSection = styled.div`
  @media (max-width: 800px) {
    margin-top: 20px;
  }

  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InfoWrapper = styled.div`
  @media (max-width: 800px) {
    gap: 6px;
  }

  display: flex;
  align-items: center;
  gap: 15px;
`;

export const InfoTextDesktop = styled.span`
  display: none;
  font-size: 14px;

  @media (min-width: 800px) {
    display: inline;
  }
`;

export const InfoDesktop = styled.span`
  display: none;

  @media (min-width: 1439px) {
    display: inline;
  }
`;

export const Info = styled.div`
  @media (max-width: 800px) {
    font-size: 18px;
  }

  font-size: 14px;
`;

export const Controls = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 800px) {
    gap: 10px;

    & > div {
      display: none;

      &:nth-last-child(-n + 2) {
        display: block;
      }

      &:nth-of-type(4) {
        padding: 6px 18px;
      }
    }
  }
`;

export const ButtonTextDesktop = styled.span`
  display: block;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Button = styled.div`
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 12px;
  font-weight: bold;
  font-size: 11px;
  display: flex;
  align-items: center;

  ${(props) =>
    props.blue &&
    css`
      background-color: #326edb;
      border: none;
      color: white;
    `}

  & > img {
    height: 12px;
    pointer-events: none;
    margin-right: 8px;
    vertical-align: middle;

    @media (max-width: 800px) {
      margin-right: 0;
    }
  }
`;
