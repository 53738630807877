import styled from "styled-components";
import playButtonPng from "../../images/icons/play-button.png";

export const Root = styled.div`
  display: flex;
  position: relative;

  & > img {
    border-radius: 4px;
    width: 100%;
  }
`;

export const PlayButton = styled.div`
  border-radius: 50%;
  background-image: url(${playButtonPng});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: calc(50% - 55px / 2);
  left: calc(50% - 55px / 2);
  width: 55px;
  height: 55px;
  cursor: pointer;
`;
