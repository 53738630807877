import { NarrowContainer } from "../../app.styled";
import {
  CardDescription,
  CardDescriptionBottom,
  CardDescriptionTop,
  CommentsWrapper,
  ContactButton,
  Essentials,
  EssentialsText,
  EssentialsTitle,
  GalleryWrapper,
  GradientLight,
  Image,
  Label,
  Labels,
  LeftRow,
  ListText,
  RightRow,
  Root,
  Rows,
  SideButton,
  SideCard,
  SideGalleries,
  SideImageWrapper,
  SideImageWrapperLast,
  SideLabel,
  SideLabels,
  SideTitle,
  SocialIcons,
  Text,
} from "./lot-description-block.styled";
import main2Jpg from "../../images/_lot-page/main2.jpg";
import main3Jpg from "../../images/_lot-page/main3.jpg";
import main4Jpg from "../../images/_lot-page/main4.jpg";
import videoJpg from "../../images/_lot-page/video.jpg";
import facebookIcon from "../../images/icons/facebook-icon.png";
import twitterIcon from "../../images/icons/twitter-icon.png";
import likeIconDarkInactive from "../../images/icons/like-icon-dark-inactive.png";
import mailIcon from "../../images/icons/mail-icon.png";
import { Gallery, GalleryImages } from "../../components/gallery/gallery";
import { Player } from "../../components/player/player";
import { galleriesData } from "../../data/galleries-data";
import auction1 from "../../images/_lot-page/auction1.jpg";
import auction2 from "../../images/_lot-page/auction2.jpg";
import auction3 from "../../images/_lot-page/auction3.jpg";
import auction4 from "../../images/_lot-page/auction4.jpg";
import auction5 from "../../images/_lot-page/auction5.jpg";
import auction6 from "../../images/_lot-page/auction6.jpg";
import auction7 from "../../images/_lot-page/auction7.jpg";
import auction8 from "../../images/_lot-page/auction8.jpg";
import auction9 from "../../images/_lot-page/auction9.jpg";
import auction10 from "../../images/_lot-page/auction10.jpg";
import auction11 from "../../images/_lot-page/auction11.jpg";
import auctionGradient from "../../images/_lot-page/auction-gradient.png";
import { BidCard } from "../../components/bid-card/bid-card";
import { CommentsForm } from "../../components/comments-form/comments-form";
import { CommentsList } from "../../components/comments-list/comments-list";

export const LotDescriptionBlock = () => {
  return (
    <Root>
      <NarrowContainer>
        <Rows>
          <LeftRow>
            <Labels>
              <Label>
                <b>MAKE:</b> Porsche
              </Label>
              <Label>
                <b>Model:</b> 911
              </Label>
              <Label>
                <b>Year:</b> 1990s
              </Label>
              <Label>
                <b>Origin:</b> Germany
              </Label>
            </Labels>
            <Text>
              This 1995 Lamborghini Countach is one of approximately 658
              examples of the 25th Anniversary model built during a three-year
              production run. The car was completed in July 1989 and was
              previously registered in New York, Texas, Florida, and Indiana
              before it was purchased by the seller in 2010. It is finished in
              black over matching leather upholstery, and power comes from a
              5.2-liter DOHC V12 paired with a five-speed manual transmission.
              Equipment includes Bosch K-Jetronic fuel injection, scissor doors,
              a rear wing, a European-market front bumper, multi-piece 15” OZ
              Racing wheels, an Alpine CD stereo, power-operated windows and
              seats, and a gated shifter. This Countach is now offered with a
              collection of spare parts, factory books, literature, service
              records, and a clean Florida title in the seller’s name.
            </Text>
            <Image src={main2Jpg} alt="main2" />
            <Text>
              The Marcello Gandini-penned bodywork was updated by Horacio Pagani
              for the 25th Anniversary edition of the Countach and features
              Kevlar panels with a revised rear bumper, engine lid, rocker
              panels, and air intakes as well as a commemorative emblem on the
              rear fascia. The bodywork is mounted over a tubular steel
              spaceframe and is finished in black. Additional features include
              front fog lights, pop-up headlights, a V-shaped rear wing, and
              NACA ducts aft of the scissor doors. The Carfax report notes an
              accident with another motor vehicle in May 1998. The seller states
              that the US-market front bumper was damaged as a result and
              subsequently replaced with a European-specification unit.
            </Text>
            <Image src={main3Jpg} alt="main3" />
            <Text>
              Introduced as a prototype in 1971, the Countach was designed by
              Bertone’s Marcello Gandini with angular wedge-shaped styling and
              scissor doors. The car features a tubular steel space frame under
              aluminum bodywork as well as NACA ducts aft of the doors and air
              intakes above the rear quarters. The 5000 QV model debuted in 1985
              and utilized Kevlar front and rear deck lids.
            </Text>
            <Image src={main4Jpg} alt="main4" />
            <Text>
              This example is finished in red and features a quad-exit exhaust,
              a black rear bumper, and a V-shaped rear wing. A European-market
              front bumper with inset Carello fog lights was reportedly
              installed by Ultrasmith Systems in 1987 along with side skirts and
              a rotating rear license plate frame. The pop-up headlights can be
              seen raised in the gallery.
            </Text>
            <Gallery exploreAll title="VIDEO" count={2}>
              <Player poster={videoJpg} />
            </Gallery>
            {galleriesData.map((gallery) => (
              <GalleryWrapper key={gallery.title}>
                <Gallery exploreAll title={gallery.title} count={gallery.count}>
                  <GalleryImages>
                    {gallery.images.map((image, index) => (
                      <img
                        key={image}
                        src={image}
                        alt={`${gallery.title} ${index + 1}`}
                      />
                    ))}
                  </GalleryImages>
                </Gallery>
              </GalleryWrapper>
            ))}
            <BidCard />
            <CommentsWrapper>
              <Gallery title="COMMENTS" count={5}>
                <CommentsForm />
                <CommentsList />
              </Gallery>
            </CommentsWrapper>
          </LeftRow>
          <RightRow>
            <div>
              <Essentials>
                <EssentialsTitle>ESSENTIALS</EssentialsTitle>
                <EssentialsText>
                  <b>Seller:</b> <u>Lambo 277</u>
                </EssentialsText>
                <EssentialsText>
                  <b>Location:</b> <u>Wekiva Spring, Florida 32779</u>
                </EssentialsText>
                <div>
                  <div>
                    <b style={{ fontSize: "12px" }}>Listing Details</b>
                  </div>
                  <div>
                    <ListText>
                      • &nbsp;&nbsp;Chassis: ZA9CA05A3KLA12729
                    </ListText>
                    <ListText>
                      • &nbsp;&nbsp;37k Kilometers (~23k Miles) Shown
                    </ListText>
                    <ListText>• &nbsp;&nbsp;5.2-Liter DOHC V12</ListText>
                    <ListText>
                      • &nbsp;&nbsp;Bosch K-Jetronic Fuel Injection
                    </ListText>
                    <ListText>
                      • &nbsp;&nbsp;Five-Speed Manual Transmission
                    </ListText>
                    <ListText>• &nbsp;&nbsp;Black Paint</ListText>
                    <ListText>• &nbsp;&nbsp;Black Leather Upholstery</ListText>
                    <ListText>
                      • &nbsp;&nbsp;Multi-Piece 15” OZ Racing Wheels
                    </ListText>
                    <ListText>
                      • &nbsp;&nbsp;European-Market Front Bumper
                    </ListText>
                    <ListText>• &nbsp;&nbsp;Rear Wing</ListText>
                    <ListText>
                      • &nbsp;&nbsp;Electronic CListTextmate Control
                    </ListText>
                    <ListText>• &nbsp;&nbsp;Alpine CD Stereo</ListText>
                    <ListText>
                      • &nbsp;&nbsp;Power-Operated Windows and Seats
                    </ListText>
                    <ListText>• &nbsp;&nbsp;Gated Shifter</ListText>
                    <ListText>• &nbsp;&nbsp;Collection of Spare Parts</ListText>
                  </div>
                </div>
                <EssentialsText>
                  <b>Private Party or Dealer:</b> Private Party
                </EssentialsText>
                <EssentialsText>
                  <b>Lot:</b> #89782
                </EssentialsText>
              </Essentials>
              <ContactButton blue>CONTACT SELLER</ContactButton>
              <SocialIcons>
                <img src={twitterIcon} alt="twitter" height={50} />
                <img src={facebookIcon} alt="facebook" height={50} />
                <img src={mailIcon} alt="mail" height={25} />
              </SocialIcons>
            </div>
            <SideGalleries>
              <SideCard>
                <SideTitle>CURRENT AUCTIONS</SideTitle>
                <SideImageWrapper>
                  <img src={auction1} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapper>
                  <CardDescription>
                    <CardDescriptionTop>
                      <div>2007</div>
                      <div>Mercedes-Benz</div>
                      <div>SLR McLaren 722 Edition</div>
                      <img src={likeIconDarkInactive} alt="like" />
                    </CardDescriptionTop>
                    <CardDescriptionBottom>
                      <div>BID:</div>
                      <div>$97,000</div>
                      <div>ENDS IN:</div>
                      <div>43 min, 22 sec</div>
                      <div>VIEW LOT</div>
                    </CardDescriptionBottom>
                  </CardDescription>
                  <img src={auction2} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapper>
                  <img src={auction3} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapper>
                  <img src={auction4} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapper>
                  <img src={auction5} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapper>
                  <img src={auction6} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapper>
                  <img src={auction7} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapper>
                  <img src={auction8} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapper>
                  <img src={auction9} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapper>
                  <img src={auction10} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapper>
                  <img src={auction11} alt="side poster" />
                </SideImageWrapper>
                <SideImageWrapperLast>
                  <img src={auctionGradient} alt="side poster" />
                  <GradientLight>
                    <span>LOAD 677 ADDITIONAL LIVE AUCTIONS</span>
                  </GradientLight>
                </SideImageWrapperLast>
                <SideButton>VIEW ALL LIVE AUCTIONS</SideButton>
                <SideButton>VIEW ALL MAKES & MODELS</SideButton>
                <SideButton>VIEW ALL AUCTION RESULTS</SideButton>
              </SideCard>
              <SideCard>
                <SideTitle>CATEGORIES</SideTitle>
                <SideLabels>
                  <SideLabel>Sedan</SideLabel>
                  <SideLabel>Race Cars</SideLabel>
                  <SideLabel>SUV</SideLabel>
                  <SideLabel>Convertibles</SideLabel>
                  <SideLabel>Classic</SideLabel>
                  <SideLabel>Vintage</SideLabel>
                </SideLabels>
              </SideCard>
              <SideCard>
                <SideTitle>ORIGIN</SideTitle>
                <SideLabels>
                  <SideLabel>American</SideLabel>
                  <SideLabel>Swedish</SideLabel>
                  <SideLabel>British</SideLabel>
                  <SideLabel>Italian</SideLabel>
                  <SideLabel>French</SideLabel>
                  <SideLabel>Japanese</SideLabel>
                  <SideLabel>German</SideLabel>
                  <SideLabel>Spanish</SideLabel>
                </SideLabels>
              </SideCard>
            </SideGalleries>
          </RightRow>
        </Rows>
      </NarrowContainer>
    </Root>
  );
};
