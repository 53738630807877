import React, { useState } from "react";
import {
  CarouselBid,
  CarouselBidWrapper,
  CarouselImage,
  Item,
  CarouselItemWrapper,
  CarouselName,
  CarouselText,
  CarouselYear,
  ImageLike,
  CarouselBidText,
} from "./carousel.styled";
import like from "../../../images/icons/like.png";
import likeActive from "../../../images/icons/like-active.png";
import { Link } from "react-router-dom";
import Progressbar from "./carousel-progress-bar";

export const CarouselItem = ({ item }) => {
  const [liked, setIsLiked] = useState(false);

  function handleLikeClick() {
    return () => {
      setIsLiked(!liked);
    };
  }

  return (
    <Item>
      <CarouselImage>
        <Link to="/lots/1">
          <img src={item.img} alt="poster" />
        </Link>
        <ImageLike
          src={liked ? likeActive : like}
          onClick={handleLikeClick()}
        />
      </CarouselImage>
      <CarouselItemWrapper>
        <Link to="/lots/1" />
        <CarouselYear>{item.year}</CarouselYear>
        <CarouselName>{item.name}</CarouselName>
        <CarouselText>{item.text}</CarouselText>
        <CarouselBidWrapper>
          <CarouselBid>
            BID: <CarouselBidText>{item.bid}</CarouselBidText>
          </CarouselBid>
          {" | "}
          <CarouselBid>
            ENDS IN: <CarouselBidText>{item.endsIn}</CarouselBidText>
          </CarouselBid>
        </CarouselBidWrapper>
        <CarouselBidWrapper>
          <Progressbar progress="80" />
        </CarouselBidWrapper>
      </CarouselItemWrapper>
    </Item>
  );
};
