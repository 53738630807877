import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  margin-top: 44px;
  gap: 24px;
  margin-bottom: 57px;

  @media (max-width: 800px) {
    margin-top: 33px;
    display: block;
  }
`;

export const LeftSide = styled.div`
  font-size: 13px;
  white-space: nowrap;

  @media (max-width: 800px) {
    font-size: 15px;
  }
`;

export const RightSide = styled.div`
  width: 350px;

  @media (max-width: 800px) {
    width: auto;
    margin-top: 18px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 15px;

  img {
    margin-left: 6px;
  }

  @media (max-width: 800px) {
    font-size: 19px;
  }
`;

export const Text = styled.div`
  color: gray;
  margin-top: 10px;
  line-height: 24px;

  @media (max-width: 800px) {
    margin-top: 18px;
    padding: 0 17px;
    font-size: 15px;
    line-height: 27px;
  }
`;

export const Control = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 13px;
  color: gray;

  @media (max-width: 800px) {
    margin-top: 23px;

    & > img {
      width: 55px;
    }

    & > u {
      font-size: 12px;
    }
  }
`;
