import React from "react";
import { Root } from "./header-with-slider-block.styled";
import { MainHeaderBlock } from "../../main-header-block/main-header-block";
import { NavigationBlock } from "../../navigation-block/navigation-block";
import { HeaderSliderBlock } from "../header-slider-block/header-slider-block";
import { MobileSearchBlock } from "../mobile-search/mobile-search-block";

export const HeaderWithSliderBlock = () => {
  return (
    <Root data-qa="Header">
      <MainHeaderBlock />
      <NavigationBlock />
      <HeaderSliderBlock />
      {/*<MobileSearchBlock />*/}
      
    </Root>
  );
};
