import {
  Control,
  LeftSide,
  RightSide,
  Root,
  Text,
  Title,
} from "./comments-list.styled";
import thumbUp from "../../images/icons/thumb-up.png";
import thumbUpControl from "../../images/icons/thumb-up-control.png";

export const CommentsList = () => {
  return (
    <Root>
      <LeftSide>Nov 14 at 2:24 PM</LeftSide>
      <RightSide>
        <Title>
          Ncdrums <img src={thumbUp} alt="thumbs up" width={13} /> 23
        </Title>
        <Text>
          <u>@Tex5089</u> it looks like in the parts removed I saw the catalytic
          converters laying there. Is this car still street legal that will pass
          a North Carolina inspection?
        </Text>
        <Control>
          <img src={thumbUpControl} alt="thumb up control" width={43} />
          <div>
            <u>Flag as not constructive</u>
          </div>
        </Control>
      </RightSide>
    </Root>
  );
};
